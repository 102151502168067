import React from 'react'
import { Head, Grid } from '@components'
import * as st from '@assets/styl/PrivacyPolicy.module.styl'

export default () => <>
  <Head path="/termos-de-uso/" />
  <Grid className={st.core}>
    <h1>Termos de uso</h1>
    <p>
      Termos de Uso – Empresas Dimas<br />
      <br />
      1. Disposições Iniciais E Termos Gerais<br />
      <br />
      O presente documento rege o relacionamento do site com os usuários, que de algum modo acessam ou interagem com as Empresas Dimas, por meio de dispositivos móveis ou qualquer outra tecnologia hábil.<br />
      <br />
      Os termos presentes neste instrumento são destinados a todos os usuários e devem sempre ser interpretados em conjunto com a Política de Privacidade dos sites das as Empresas Dimas.<br />
      <br />
      Destacamos que todas as disputas ou reivindicações decorrentes da relação entre as Empresas Dimas e o Usuário, ainda que não contratuais, serão regidas pela legislação brasileira.<br />
      <br />
      Recomendamos que todos os usuários dediquem um momento para leitura deste instrumento antes de se cadastrarem ou fornecerem seus dados pessoais no nosso site.<br />
      <br />
      2. Regras De Utilização Do Site<br />
      <br />
      Para acessar o nosso site e utilizar alguma das ferramentas disponíveis para nos contatar e fazer login na Área do Cliente, o usuário/titular deve:<br />
      <br />
      · a) Ser maior de 18 anos e possuir capacidade civil;<br />
      <br />
      · b) Garantir que toda a informação transmitida no registro e em todos os outros momentos é verdadeira, precisa, atual e completa;<br />
      <br />
      · c) Aceitar que todas as informações enviadas por si, as Empresas Dimas serão armazenadas em banco de dados;<br />
      <br />
      · d) No caso da conta destinada ao acesso da “Área do Cliente”, ser responsável pelo sigilo, exclusividade e segurança de sua senha de acesso. O próprio Usuário é o único autorizado a acessar a própria na plataforma e é responsável por qualquer atividade que ocorra em sua conta;<br />
      <br />
      · e) Não utilizar o site para propósitos ilegais ou outros que não sejam autorizados pela legislação brasileira;<br />
      <br />
      · f) Concordar que é o único responsável pela própria conduta e por qualquer informação, material, arquivo, texto, link e qualquer outro conteúdo ou material que inserir no nosso site;<br />
      <br />
      · g) Concordar em não fornecer nenhum dado pessoal que não lhe tenha sido solicitado e não seja imprescindível para a finalidade do tratamento de dados realizado pelas Empresas Dimas;<br />
      <br />
      · h) Concordar em não enviar às nossas Empresas conteúdo de cunho difamatório, calunioso, injurioso, violento, obsceno, pornográfico, ilegal ou de qualquer modo ofensivo, seja ou não esse material protegido por lei.<br />
      <br />
      3. Uso Das Ferramentas Para Contato<br />
      <br />
      O acesso e a utilização do nosso site são oferecidos ao usuário unicamente para o seu uso pessoal.<br />
      <br />
      Sendo assim, o usuário declara expressamente que apenas acessará ou utilizará os sites das Empresas Dimas para os propósitos a que ele se destina e que está ciente da responsabilização civil e criminal<br />
      <br />
      pelo fornecimento de dados falsos ou de terceiros, sendo passível por danos diretos ou indiretos causados as Empresas Dimas ou a terceiros que tenham acesso.<br />
      <br />
      Entre outros fatores, o usuário concorda que não poderá:<br />
      <br />
      · Enviar ou transmitir quaisquer informações que possam induzir outros a erro, incitem a violência ou intolerância de qualquer natureza; ofensivas ou que contrariem a lei e/ou a ordem pública;<br />
      <br />
      · Efetuar cadastro ou fornecer dados em um dos campos destinados ao contato com o cliente utilizando-se de informações falsas ou de propriedade de terceiros;<br />
      <br />
      · Enviar ou transmitir informações de terceiros, ou de qualquer forma alterar, remover ou corromper informações de terceiros;<br />
      <br />
      · Enviar ou transmitir quaisquer formas de códigos maliciosos, vírus eletrônicos ou conteúdo destrutivo que possam vir a expor informações das Empresas Dimas e de terceiros;<br />
      <br />
      · Danificar, desabilitar, sobrecarregar, prejudicar o nosso site.<br />
      <br />
      4. Violação Aos Termos De Uso<br />
      <br />
      A infração a qualquer das proibições contidas nestes Termos de Uso acarretará na imediata exclusão do titular de nossa base de dados, com a consequente interrupção do contato e eventuais negociações.<br />
      <br />
      As violações também podem sujeitar o Usuário à responsabilização cível e/ou penal cabível.<br />
      <br />
      Ademais, nos reservamos ao direito de, mesmo sem aviso prévio, monitorar, editar, bloquear ou mesmo remover qualquer conteúdo ou usuário que possa estar em desacordo com a sistemática dos presentes Termos de Uso ou com a legislação brasileira.<br />
      <br />
      5. Foro Aplicável<br />
      <br />
      Os presentes Termos de Uso consistem no acordo total e pleno entre as Empresas Dimas e o Usuário e substituem qualquer acordo prévio, oral ou escrito feito entre ambos. Fica eleito o foro da comarca de São José - SC para a resolução de eventuais conflitos.<br />
      <br />
      As Empresas Dimas usam cookies para personalizar anúncios e melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa Política de Privacidade e Termos de Uso.<br />
      <br />
      O presente Termo de Uso entra em vigor em 04 de novembro de 2021.
    </p>
  </Grid>
</>